/* center map in div */
.map {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
}
.map path {
    pointer-events: all;
    fill: #c084fc
    
  }
  .map path:hover {
    opacity: 0.50;
    cursor: pointer;
  }
.map path:active {
    fill: #4c1d95;
    /* opacity: ; */
    cursor: pointer;
}