.Spreadsheet__table {
  width: 100%;
}
.Spreadsheet__cell--readonly {
  color: #000 !important;
  cursor: not-allowed !important;
}

.Spreadsheet__header {
  background-color: #f9fafb !important;
  font-weight: bold !important;
}

/*Row Numbers */
.Spreadsheet__table col:nth-child(1) {
  width: 50px; /* Width of the first column */
}
/* Line Item */
.Spreadsheet__table col:nth-child(2) {
  font-weight: bold !important; /* Bold second column */
  width: 400px; /* Minimum width of the second column */
}
/* Included */
.Spreadsheet__table col:nth-child(3) {
  width: 100px;
}
/* Unit Price */
.Spreadsheet__table col:nth-child(4) {
  width: 100px;
}
/* Quantity */
.Spreadsheet__table col:nth-child(5) {
  width: 100px;
}
/* Total */
.Spreadsheet__table col:nth-child(6) {
  min-width: 400px;
  flex-grow: 1;
}
/* Bold the second row */
.Spreadsheet__table tr:nth-child(2) {
  font-weight: bold !important;
}
/* SpreadsheetComponent.css */
.instructions-field {
  width: 600px; /* Set a fixed width for the Instructions field */
}

.dynamic-field {
  flex-grow: 1; /* Make the field dynamic in width */
  min-width: 100px; /* Set a minimum width */
}
.fixed-field {
  width: 10px; /* Set a fixed width */
}
