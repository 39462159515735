/* .chat-bubble-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 100%;
} */
.chat-bubble {
  position: relative;
  display: block;
  width: fit-content;
  padding: 1rem 1rem;
  max-width: 90%;
  background-color: var(--bubble-bg, #e5e7eb); /* Default fallback */
  color: var(--bubble-text, #000); /* Default fallback */
  border-radius: 0.5rem;
}

.chat-bubble:before {
  content: "";
  position: absolute;
  bottom: 0rem; /* Perfectly aligned at the bottom */
  width: 0.75rem; /* Tail size matches */
  height: 0.75rem;
  background-color: inherit;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.chat-start {
  display: flex;
  justify-content: flex-start;
}

.chat-start .chat-bubble {
  border-bottom-left-radius: 0; /* Remove bottom-left corner */
}

.chat-start .chat-bubble:before {
  left: -0.7rem; /* Correctly positioned for left tail */
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
}

.chat-end {
  display: flex;
  justify-content: flex-end;
}

.chat-end .chat-bubble {
  border-bottom-right-radius: 0; /* Remove bottom-right corner */
  width: 90%; /* Adjusted width for the left-aligned bubble */
}

.chat-end .chat-bubble:before {
  right: -0.7rem; /* Match the exact offset for the right tail */
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 3 3 L 0 3 L 0 0 C 0 1 2 3 3 3'/%3e%3c/svg%3e");
}
